import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table, Dropdown, Button } from "react-bootstrap";
import UserFriendlyDate from '../../Utils/UserFriendlyDate';

import api from "../../../utils/agentApi";

function ListSequences() {
  const [sequences, setAgents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchLeads = async () => {
      setIsLoading(true);
      const response = await api.get("/get_sequences");
      setAgents(response.data.data);
      setIsLoading(false);
    };
    fetchLeads();
  }, []);

  const handleDelete = (id) => {
    
    return async () => {
      console.log(id);
      try {
        let sequenceData = {
            "seq_id": id
        };
        await api.post(`/remove_sequence`, sequenceData);
        const response = await api.get("/get_sequences");
        setAgents(response.data.data);
      } catch (error) {
        alert("Failed to delete customer");
      }
    };
  };


  return (
    <Container>
      {isLoading && <p>Loading sequences...</p>}
      {sequences.length === 0 && !isLoading && <p>No Sequences found.</p>}
      <h1>Sequences{" "}
        <Button href="/workflows/sequences/create" variant="outline-primary">
          New Sequence
        </Button></h1>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Created</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {sequences.map((sequence) => (
            <tr key={sequence.id}>
              <td><a href={`/workflows/sequences/view/${sequence.id}`}>{sequence.name}</a></td>
              <td>{sequence.description}</td>
              <td>
              <UserFriendlyDate timestamp={sequence.created_at} /></td>
              <td>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Actions
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="/workflow/sequences/edit">
                      Edit
                    </Dropdown.Item>
                    <Dropdown.Item as="button" onClick={handleDelete(sequence.id)}>
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default ListSequences;
