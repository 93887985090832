import React from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

const ConversionsChart = ({input_data}) => {
  const data = {
    labels: input_data.map(s => s.name),
    datasets: [
      {
        label: 'Conversions',
        data: input_data.map(s => s.conversions),
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default ConversionsChart;
