import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Table, Dropdown } from "react-bootstrap";
import UserFriendlyDate from "../Utils/UserFriendlyDate";
import api from "../../utils/api";

const List = () => {
  const [listReports, setListReports] = useState([]);

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const response = await api.get(
          "/report/all"
        );
        setListReports(response.data); // Adjust based on your API structure
      } catch (error) {
        console.error("Failed to fetch customers", error);
      }
    };

    fetchReports();
  }, []);

  return (
    <Container>
      <h1 className="mt-4">
        All Projects{" "}
        <Button href="/report/create" variant="outline-primary">
          Add
        </Button>
      </h1>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Customer</th>
            <th>Type</th>
            <th>Created</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {listReports.map((report) => (
            <tr key={report.id}>
              <td>{report.name}</td>
              <td>{report.customer.customer_name}</td>
              <td>{report.type}</td>
              <td>
                <UserFriendlyDate timestamp={report.created_at} />
              </td>
              <td>{report.state}</td>
              <td width="120px">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Action
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href={`/report/view/${report.id}`}>
                      View
                    </Dropdown.Item>
                    <Dropdown.Item as="button">Delete</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default List;
