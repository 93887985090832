import React, { useState, useEffect } from "react";
import {Container, Row, Col, Table, Form, InputGroup, Dropdown, Button} from 'react-bootstrap';
import api from '../../utils/api';

const Create = () => {
    const [url, setUrl] = useState('');
    const [learnwebsite, setLearnWebsite] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const response = await api.post(`/demo/research?url=${encodeURIComponent(url)}`);
        console.log(response.data);
        setLearnWebsite(response.data.demo.learn_website);
        setIsLoading(false);
        setUrl('');
    };

    return (
        <Container>
            <h1>Create a new lead</h1>
            {learnwebsite && (
                <div>
                    <h2>Learn Website</h2>
                    <p>{learnwebsite}</p>
                </div>
            )}
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                    <Form.Label>URL</Form.Label>
                    <Form.Control
                        type="text"
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                        placeholder="Enter URL"
                    />
                </Form.Group>
                <Button variant="primary" type="submit" disabled={isLoading}>
                    {isLoading ? 'Loading...' : 'Submit'}
                </Button>
            </Form>
        </Container>
    );
};

export default Create;