import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, FormGroup, FormControl } from "react-bootstrap";
import api from "../../utils/api";
const Create = () => {
  const navigate = useNavigate();

  const [customerName, setCustomerName] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const apiUrl = "/customer/";
    const data = {
      'customer_name': customerName
    };

    try {
      const response = await api.post(apiUrl, data);
      navigate(`/customer/list`);
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Error uploading file");
    } finally {
      //setUploadProgress(0); // Reset progress
    }
  };

  return (
    <div className="container">
      <h1 className="mt-4">Create New Customer</h1>
      <Form>
        <FormGroup className="mb-3">
          <Form.Label>Name</Form.Label>
          <FormControl
            type="text"
            placeholder="Customer Name"
            id="customerName"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
          />
        </FormGroup>

        {/*         <Form.Group className="mb-3" controlId="reportTags">
          <Form.Label>Tags</Form.Label>
          <Form.Control type="text" placeholder="Add tags" />
        </Form.Group> */}

        <Button variant="primary" type="submit" onClick={handleSubmit}>
          Create
        </Button>
      </Form>
    </div>
  );
};

export default Create;
