import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, FormGroup, FormControl } from "react-bootstrap";
import api from "../../utils/api";
const Create = () => {
  const navigate = useNavigate();

  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userRole, setUserRole] = useState("admin");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const apiUrl = "/users/";
    const data = {
      'name': userName,
      'email': userEmail,
      'password': userPassword,
      'role': userRole
    };

    try {
      const response = await api.post(apiUrl, data);
      navigate(`/users/list`);
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Error uploading file");
    } finally {
      //setUploadProgress(0); // Reset progress
    }
  };

  return (
    <div className="container">
      <h1 className="mt-4">Create New User</h1>
      <Form>
        <FormGroup className="mb-3">
          <Form.Label>Name</Form.Label>
          <FormControl
            type="text"
            placeholder="User Name"
            id="userName"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
        </FormGroup>
        <FormGroup className="mb-3">
          <Form.Label>Email</Form.Label>
          <FormControl
            type="email"
            placeholder="email"
            id="userEmail"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
          />
        </FormGroup>

        <FormGroup className="mb-3">
          <Form.Label>Password</Form.Label>
          <FormControl
            type="password"
            placeholder="password"
            id="userPassword"
            value={userPassword}
            onChange={(e) => setUserPassword(e.target.value)}
          />
        </FormGroup>
        <FormGroup className="mb-3">
          <Form.Label>Role</Form.Label>
          <FormControl
            as="select"
            id="userRole"
            value={userRole}
            onChange={(e) => setUserRole(e.target.value)}
          >
            <option value="admin">Admin</option>
            <option value="user">User</option>
          </FormControl>  
        </FormGroup>

        {/*         <Form.Group className="mb-3" controlId="reportTags">
          <Form.Label>Tags</Form.Label>
          <Form.Control type="text" placeholder="Add tags" />
        </Form.Group> */}

        <Button variant="primary" type="submit" onClick={handleSubmit}>
          Create
        </Button>
      </Form>
    </div>
  );
};

export default Create;
