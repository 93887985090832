import react, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { Form, Button, FormGroup, FormControl } from "react-bootstrap";
import api from "../../utils/api";

const Account = () => {
  const [user, setUser] = useState(null);
  const [userPassword, setUserPassword] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await api.get("/users/me");
        setUser(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchUser();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const apiUrl = `/users/${user.id}`;
    const data = {
      password: userPassword,
    };

    try {
      const response = await api.put(apiUrl, data);
      alert("Password updated successfully");
    } catch (error) {
      console.error("Error updating password:", error);
      alert("Error updating password");
    } finally {
      //setUploadProgress(0); // Reset progress
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      <h1>Account</h1>
      <p>Welcome, {user.name}</p>
      <p>Email: {user.email}</p>
      <p>Role: {user.role}</p>

      <Form className="mt-4">
        <FormGroup className="mb-3">
          <Form.Label>Name</Form.Label>
          <FormControl
            type="password"
            placeholder="New password"
            id="userPassword"
            value={userPassword}
            onChange={(e) => setUserPassword(e.target.value)}
          />
        </FormGroup>

        <Button variant="primary" type="submit" onClick={handleSubmit}>
          Update
        </Button>
      </Form>
    </div>
  );
};

export default Account;
