import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import {
  Container,
  Row,
  Col,
  Form,
  Badge,
  FormGroup,
  FormControl,
  Button,
  Table,
  Dropdown,
} from "react-bootstrap";

import api from "../../utils/api";

const FileUpload = ({ report_id, report_type }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [columns, setColumns] = useState([]);
  const [customerID, setCustomerID] = useState("");
  const [zipCodeCol, setZipCodeCol] = useState("");
  const [spendCol, setSpendCol] = useState("");
  const [conversionsCol, setConversionsCol] = useState("");
  const [countCol, setCountCol] = useState("");
  const [downloadLink, setDownloadLink] = useState("");
  const [metrics, setMetrics] = useState(null);
  const [removedRows, setRemovedRows] = useState([]);
  const [isUploaded, setIsUploaded] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState("fileSelect"); // 'fileSelect', 'typeSelect', 'form'

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setUploadProgress(0); // Reset progress whenever a new file is selected
    parseCSVHeader(event.target.files[0]);
    setStep("form");
  };

  const parseCSVHeader = (file) => {
    Papa.parse(file, {
      complete: function (results) {
        if (results.data.length > 0) {
          setColumns(results.data[0]);
        }
      },
      preview: 1,
    });
  };

  const handleUpload = async () => {
    setIsLoading(true); // Indicate loading
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("report_id", report_id);

    formData.append("zip_code_col", zipCodeCol);
    if (spendCol) formData.append("spend_col", spendCol);
    if (conversionsCol) formData.append("conversions_col", conversionsCol);
    if (countCol) formData.append("count_col", countCol);

    try {
      /*       for (let [key, value] of formData.entries()) {
        console.log(key, value);
      } */

      const response = await api.post(
        "/report/upload-csv/",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted); // Update upload progress
          },
        }
      );

      window.location.reload();
      //const { url, metrics, removed } = response.data.response;
      //setDownloadLink(url);
      //setMetrics(metrics);
      //setRemovedRows(removed);
      //setIsUploaded(true);
      //setStep("results");
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Error uploading file");
    } finally {
      setIsLoading(false); // Reset loading indicator
      setUploadProgress(0); // Reset progress
    }
  };

  return (
    <div>
      {step === "fileSelect" && (
        <FormControl
          type="file"
          accept=".csv"
          id="file"
          onChange={handleFileChange}
        />
      )}

      {step === "form" && selectedFile && (
        <>
          {isLoading && (
            <div className="progress">
              <div
                className="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                style={{ width: `${uploadProgress}%` }}
              >
                {uploadProgress}%
              </div>
            </div>
          )}
          {!isLoading && (
            <Container>
              <Form>
                <Form.Group className="mb-3" controlId="zipCodeCol">
                  <Form.Label>Zip code Column</Form.Label>
                  <Form.Select
                    aria-label="Zip code"
                    id="zipCodeCol"
                    value={zipCodeCol}
                    onChange={(e) => setZipCodeCol(e.target.value)}
                  >
                    <option value="">Select column</option>
                    {columns.map((col, index) => (
                      <option key={index} value={index}>
                        {col}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Form>

              {/* Conditional rendering for Campaign or Customer level */}
              {report_type === "campaign" && (
                <>
                  <Form.Group className="mb-3" controlId="spendCol">
                    <Form.Label>Cost Column</Form.Label>
                    <Form.Select
                      aria-label="Cost"
                      id="spendCol"
                      value={spendCol}
                      onChange={(e) => setSpendCol(e.target.value)}
                    >
                      <option value="">Select column</option>
                      {columns.map((col, index) => (
                        <option key={index} value={index}>
                          {col}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="conversionsCol">
                    <Form.Label>Conversions Column</Form.Label>
                    <Form.Select
                      aria-label="Conversions"
                      id="conversionsCol"
                      value={conversionsCol}
                      onChange={(e) => setConversionsCol(e.target.value)}
                    >
                      <option value="">Select column</option>
                      {columns.map((col, index) => (
                        <option key={index} value={index}>
                          {col}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </>
              )}
              {report_type === "customer" && (
                <Form.Group className="mb-3" controlId="countCol">
                  <Form.Label>Count Column</Form.Label>
                  <Form.Select
                    aria-label="Count"
                    id="countCol"
                    value={countCol}
                    onChange={(e) => setCountCol(e.target.value)}
                  >
                    <option value="">Select column</option>
                    {columns.map((col, index) => (
                      <option key={index} value={index}>
                        {col}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              )}
              <div className="d-grid gap-2">
                <Button
                  variant="secondary"
                  onClick={() => setStep("fileSelect")}
                >
                  Back
                </Button>
                <Button
                  variant="primary"
                  onClick={handleUpload}
                  disabled={isLoading}
                >
                  Upload
                </Button>
              </div>
            </Container>
          )}
        </>
      )}

      {step === "results" && isUploaded && (
        <div className="upload-results">
          <a href={downloadLink} download>
            Download Processed File
          </a>
          <div className="metrics">
            <p>
              <strong>Total Rows Original:</strong>{" "}
              {metrics.total_rows_original}
            </p>
            <p>
              <strong>Total Invalid ZIP Codes:</strong>{" "}
              {metrics.total_invalid_zip_codes}
            </p>
            <p>
              <strong>Total Valid ZIP Codes Changed:</strong>{" "}
              {metrics.total_valid_zip_codes_changed}
            </p>
            <p>
              <strong>Final Count of ZIP Codes:</strong>{" "}
              {metrics.final_count_zip_codes}
            </p>
          </div>
          {removedRows && removedRows.length > 0 && (
            <div className="removed-items">
              <h3>Removed Items:</h3>
              <ul>
                {removedRows.map((item, index) => (
                  <li key={index}>{Object.values(item)[0].join(", ")}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default FileUpload;
