import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import api from "../../utils/api";

const View = () => {
  let { checksum } = useParams();

  const [demo, setDemo] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const FRONTEND_BASE_URL = process.env.REACT_APP_FRONTEND_BASE_URL;

  useEffect(() => {
    const fetchDemo = async () => {
      setIsLoading(true);
      const response = await api.get(`/demo/results?checksum=${checksum}`);
      setDemo(response.data);
      setIsLoading(false);
    };
    fetchDemo();
  }, [checksum]);

  return (
    <Container>
      {isLoading && <p>Loading prompt...</p>}
      {!isLoading && (
        <>
          <h1>{demo.url}</h1>
          <Row>
            <Col xs={2}>
              <strong>Result page: </strong>
            </Col>
            <Col><a href={`${FRONTEND_BASE_URL}results/${demo.checksum}`} target="_blank">View</a></Col>
          </Row>
          <Row>
            <Col xs={2}>
              <strong>Status: </strong>
            </Col>
            <Col>{demo.status}</Col>
          </Row>

          <Row>
            <Col xs={2}>
              <strong>website_language: </strong>
            </Col>
            <Col>{demo.website_language}</Col>
          </Row>
          <Row>
            <Col xs={2}>
              <strong>life_outlook_calculation: </strong>
            </Col>
            <Col>{demo.life_outlook_calculation}</Col>
          </Row>
          <Row>
            <Col xs={2}>
              <strong>life_outlook: </strong>
            </Col>
            <Col>{demo.life_outlook}</Col>
          </Row>
          <Row>
            <Col xs={2}>
              <strong>score_regions: </strong>
            </Col>
            <Col>{demo.score_regions}</Col>
          </Row>
          <Row>
            <Col xs={2}>
              <strong>output_regions_score: </strong>
            </Col>
            <Col>{demo.output_regions_score}</Col>
          </Row>
          <Row>
            <Col xs={2}>
              <strong>output_regions_analysis: </strong>
            </Col>
            <Col>{demo.output_regions_analysis}</Col>
          </Row>
          <Row>
            <Col xs={2}>
              <strong>score_generation: </strong>
            </Col>
            <Col>{demo.score_generation}</Col>
          </Row>
          <Row>
            <Col xs={2}>
              <strong>generation_output: </strong>
            </Col>
            <Col>{demo.generation_output}</Col>
          </Row>
          <Row>
            <Col xs={2}>
              <strong>overall_calculation: </strong>
            </Col>
            <Col>{demo.overall_calculation}</Col>
          </Row>
          <Row>
            <Col xs={2}>
              <strong>what_customers_see: </strong>
            </Col>
            <Col>{demo.what_customers_see}</Col>
          </Row>

          <Row>
            <Col xs={2}>
              <strong>summary: </strong>
            </Col>
            <Col>{demo.summary}</Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default View;
