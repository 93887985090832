import React, { useState, useEffect } from "react";
import { Container, Row, Col, Badge, Card } from "react-bootstrap";
import api from "../../utils/api";

import { useParams } from "react-router-dom";
import Files from "./View/Files";
import FileUpload from "./FileUpload";
import Heatmap from "./View/Visualization/Heatmap";
import ConversionsChart from "./View/Visualization/ConversionsChart";

const View = () => {
  let { id } = useParams();

  const [reportState, setReportState] = useState("draft");
    
  const stateData = [
    { state: "CA", value: 10 },
    { state: "TX", value: 20 },
    // Add all states
  ];

  const [reportDetail, setReportDetail] = useState({
    name: "",
    description: "",
    customer: "",
    type: "",
    state: "",
  });

  useEffect(() => {
    const fetchReport = async () => {
      try {
        const response = await api.get(
          `/report/${id}`
        );

        setReportDetail(response.data); // Adjust based on your API structure
        setReportState(response.data.state);
      } catch (error) {
        console.error("Failed to fetch customers", error);
      }
    };

    fetchReport();
  }, [id]);

  return (
    <Container>
      <h1 className="mt-4">
        Report details
        {reportState === "draft" ? (
          <Badge bg="secondary">{reportDetail.state}</Badge>
        ) : (
          <Badge bg="primary">{reportDetail.state}</Badge>
        )}
      </h1>
      <Row className="py-2">
        <Col>
          <strong>Name: </strong>
          {reportDetail.name}
        </Col>
        <Col>
          <strong>State: </strong>
          {reportState === "draft" ? (
            <Badge bg="secondary">{reportDetail.state}</Badge>
          ) : (
            <Badge bg="primary">{reportDetail.state}</Badge>
          )}
        </Col>
      </Row>
      <Row className="py-2">
        <Col>
          <strong>Customer: </strong>
          <a href={`/customer/view/${reportDetail.customer_id}`}>
            {reportDetail.customer.customer_name}
          </a>
        </Col>
        <Col>
          <strong>Type:</strong> {reportDetail.type}
        </Col>
      </Row>
      <Row className="py-2">
        <Col>
          <strong>Description:</strong> {reportDetail.description}
        </Col>
      </Row>

      

      {reportState === "merged" && (
        <Container>
          <h4 className="mt-4">Summary</h4>
          <Row>
            <Col>
              {reportDetail.summary && (
                <Card>
                  <Card.Header>Conversions by states</Card.Header>
                  <Card.Body>
                    <ConversionsChart input_data={reportDetail.summary} />
                  </Card.Body>
                </Card>
              )}
            </Col>
          </Row>
        </Container>
      )}

    

      {reportState === "draft" && (
        <>
          <h4 className="mt-4">Upload a CSV file</h4>
          <FileUpload
            report_id={reportDetail.id}
            report_type={reportDetail.type}
          />
        </>
      )}

<Files report_id={id} report_state={reportDetail.state} />
    </Container>
  );
};

export default View;
