import React from 'react';
import TopNav from '../TopNav';
import LeftNav from '../LeftNav';
import { Container } from "react-bootstrap";

const MainLayout = ({ children }) => {
  return (
    <div>
      <TopNav />
      <Container fluid>
          <div className="d-flex">
      <LeftNav />
      {children}
      </div>
      </Container>
    </div>
  );
};

export default MainLayout;
