import React from 'react';

const DataSummary = ({ data }) => {
  return (
    <ul>
      <li className='small'><strong>Raw File Record Count:</strong> {data.orginal_file_rows}</li>
      <li className='small'><strong>Final Count of Zip Codes:</strong> {data.final_count_zip_codes}</li>
      <li className='small'><strong>Invalid Zip Codes:</strong> {data.total_invalid_zip_codes}</li>
      <li className='small'><strong>Valid Zip Codes Modified:</strong> {data.total_valid_zip_codes_changed}</li>
      <li className='small'><strong>Rows Merged:</strong> {data.merged_rows}</li>
    </ul>
  );
};

export default DataSummary;
