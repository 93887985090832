import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Form,
  InputGroup,
  Dropdown,
  Button,
} from "react-bootstrap";
import api from "../../../utils/agentApi";

function CreateAgent() {
  const [agentName, setAgentName] = useState("");
  const [agentDescription, setAgentDescription] = useState("");

  const [agentType, setAgentType] = useState("");

  const [prompt, setPrompt] = useState("");
  const [modelName, setModelName] = useState("");
  const [frequencyPenalty, setFrequencyPenalty] = useState(0.5);
  const [maxTokens, setMaxTokens] = useState(1000);
  const [presencePenalty, setPresencePenalty] = useState(0.5);
  const [responseFormat, setResponseFormat] = useState("text");
  const [stream, setStream] = useState(false);
  const [temperature, setTemperature] = useState(0.7);
  const [customCode, setCustomCode] = useState("");
  const [customInput, setCustomInput] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const agentData = {
        name: agentName,
        description: agentDescription,
        system_prompt: prompt,
        model: modelName,
        frequency_penalty: frequencyPenalty,
        max_tokens: maxTokens,
        presence_penalty: presencePenalty,
        response_format: responseFormat,
        stream: stream,
        temperature: temperature,
        custom_code: customCode,
        custom_input: {"filePath": customInput}
    };

    try {
      const response = await api.post('/new_or_update_agent', agentData);
      console.log(response.data);
      if (response.status === 200) {
        alert("Agent created successfully");
      }
    }
    catch (error) {
        console.error(error);
    }
    setIsLoading(false);
  };

  return (
    <Container>
      <h1>Create a new Agent</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Agent Name</Form.Label>
          <Form.Control
            type="text"
            value={agentName}
            onChange={(e) => setAgentName(e.target.value)}
            placeholder="Name"
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Agent Description</Form.Label>
          <Form.Control
            as="textarea"
            value={agentDescription}
            onChange={(e) => setAgentDescription(e.target.value)}
            placeholder="Description"
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Agent Type</Form.Label>
          <Form.Control
            as="select"
            value={agentType}
            onChange={(e) => setAgentType(e.target.value)}
          >
            <option value="">Select</option>
            <option value="ai">AI</option>
            <option value="calc">Calculation</option>
          </Form.Control>
        </Form.Group>

        {agentType === "ai" && (
          <>
            <h4>AI Agent</h4>
            <Form.Group className="mb-3">
              <Form.Label>Prompt</Form.Label>
              <Form.Control
                as="textarea"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                placeholder="Prompt"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Model Name</Form.Label>
              <Form.Control
                as="select"
                value={modelName}
                onChange={(e) => setModelName(e.target.value)}
              >
                <option value="">Select</option>
                <option value="gpt-4o">GPT-4o</option>
                <option value="gpt-4-turbo">GPT-4 Turbo</option>
                <option value="gpt-3.5-turbo">GPT-3.5 Turbo</option>
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Frequency Penalty</Form.Label>
              <Form.Control
                type="number"
                value={frequencyPenalty}
                onChange={(e) => setFrequencyPenalty(e.target.value)}
                placeholder="Frequency Penalty"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Max Tokens</Form.Label>
              <Form.Control
                type="number"
                value={maxTokens}
                onChange={(e) => setMaxTokens(e.target.value)}
                placeholder="Max Tokens"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Presence Penalty</Form.Label>
              <Form.Control
                type="number"
                value={presencePenalty}
                onChange={(e) => setPresencePenalty(e.target.value)}
                placeholder="Presence Penalty"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Response Format</Form.Label>
              <Form.Control
                as="select"
                value={responseFormat}
                onChange={(e) => setResponseFormat(e.target.value)}
              >
                <option value="text">Text</option>
                <option value="json">JSON</option>
                </Form.Control> 
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Stream</Form.Label>
              <Form.Check
                type="checkbox"
                value={stream}
                id="stream"
                onChange={(e) => setStream(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Temperature</Form.Label>
              <Form.Control
                type="number"
                value={temperature}
                onChange={(e) => setTemperature(e.target.value)}
                placeholder="Temperature"
              />
            </Form.Group>
          </>
        )}

        {agentType === "calc" && (
          <>
            <h4>Calculation Agent</h4>
            <Form.Group className="mb-3">
              <Form.Label>Custom Code</Form.Label>
              <Form.Control
                as="textarea"
                value={customCode}
                onChange={(e) => setCustomCode(e.target.value)}
                placeholder=""
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Custom Input</Form.Label>
              <Form.Control
                as="textarea"
                value={customInput}
                onChange={(e) => setCustomInput(e.target.value)}
                placeholder=""
              />
            </Form.Group>
          </>
        )}

        <Button variant="primary" type="submit" disabled={isLoading}>
          {isLoading ? "Loading..." : "Submit"}
        </Button>
      </Form>
    </Container>
  );
}

export default CreateAgent;
