import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table, Dropdown, Button } from "react-bootstrap";
import UserFriendlyDate from "../Utils/UserFriendlyDate";
import api from "../../utils/api";

const List = () => {
  const [listUsers, setListUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await api.get("/users/all");
        console.log(response.data);

        setListUsers(response.data); // Adjust based on your API structure
      } catch (error) {
        console.error("Failed to fetch Users", error);
      }
    };

    fetchUsers();
  }, []);

  const handleDelete = (id) => {
    return async () => {
      try {
        await api.delete(`/users/${id}`);
        const response = await api.get("/users/all");
        setListUsers(response.data); // Adjust based on your API structure
      } catch (error) {
        console.error("Failed to delete customer", error);
      }
    };
  };

  return (
    <Container>
      <h1 className="mt-4">
        All Users{" "}
        <Button href="/users/create" variant="outline-primary">
          Add
        </Button>
      </h1>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>id</th>
            <th>email</th>
            <th>role</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {listUsers.map((user) => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>{user.email}</td>
              <td>{user.role}</td>
              <td width="120px">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Action
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href={`/users/view/${user.id}`}>
                      View
                    </Dropdown.Item>
                    <Dropdown.Item as="button" onClick={handleDelete(user.id)}>
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default List;
