import React from "react";
import { useAuth } from "./AuthContext";
import {
  HouseDoor,
  Person,
  PersonGear,
  GraphUp,
  BoxArrowRight,
  PlusCircle,
  ChatLeftText,
  CurrencyDollar,
  Robot
} from "react-bootstrap-icons";

const LeftNav = () => {
  const { logout } = useAuth();

  const handleLogout = (e) => {
    e.preventDefault();
    logout();
  };

  return (
    <div id="sidebar-wrapper" style={{ width: "250px" }}>
      <div className="list-group list-group-flush">
        <a
          href="/"
          className="list-group-item list-group-item-action d-flex align-items-center"
        >
          <PlusCircle className="bi me-2" size={20} /> New project
        </a>
        <a
          href="/report/list"
          className="list-group-item list-group-item-action d-flex align-items-center"
        >
          <GraphUp className="bi me-2" size={20} /> Projects
        </a>
        <a
          href="/workflows/"
          className="list-group-item list-group-item-action d-flex align-items-center"
        >
          <Robot className="bi me-2" size={20} /> Worfklows
        </a>
        <a
          href="/customer/list"
          className="list-group-item list-group-item-action d-flex align-items-center"
        >
          <Person className="bi me-2" size={20} /> Customers
        </a>
        <a
          href="/prompts/list"
          className="list-group-item list-group-item-action d-flex align-items-center"
        >
          <ChatLeftText className="bi me-2" size={20} /> Prompts
        </a>
        <a
          href="/leads/list"
          className="list-group-item list-group-item-action d-flex align-items-center"
        >
          <CurrencyDollar className="bi me-2" size={20} /> Leads (free assessment)
        </a>
        <a
          href="/users/list"
          className="list-group-item list-group-item-action d-flex align-items-center"
        >
          <PersonGear className="bi me-2" size={20} /> Users
        </a>
        <a
          as="button"
          onClick={handleLogout}
          className="list-group-item list-group-item-action d-flex align-items-center"
        >
          <BoxArrowRight className="bi me-2" size={20} /> Logout
        </a>
        {/*       <a
        as="button"
        className="list-group-item list-group-item-action d-flex align-items-center"
      >
        <Gear className="bi me-2" size={20} /> Settings
      </a> */}
      </div>
    </div>
  );
};

export default LeftNav;
