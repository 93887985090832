import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Form,
  InputGroup,
  Dropdown,
  Button,
} from "react-bootstrap";
import api from "../../../utils/agentApi";

function UploadFile() {

  const [fileName, setFiletName] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {

      const formData = new FormData();
      formData.append("file", fileName);

      const response = await api.post('/upload_customer_file', formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response.data);
      
      if (response.status === 200) {
        alert("File uploaded successfully");
      }
    }
    catch (error) {
        console.error(error);
    }
    setIsLoading(false);
  };

  return (
    <Container>
      <h1>Upload a new file</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>File Name</Form.Label>
          <Form.Control
            type="file"
            onChange={(e) => setFiletName(e.target.files[0])}
          />
        </Form.Group>

        <Button variant="primary" type="submit" disabled={isLoading}>
          {isLoading ? "Loading..." : "Submit"}
        </Button>
      </Form>
    </Container>
  );
}

export default UploadFile;
