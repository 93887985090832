import React, { useState, useEffect } from "react";
import {Container, Row, Col, Table, Dropdown, Button} from 'react-bootstrap';
import api from '../../utils/api';
import UserFriendlyDate from '../Utils/UserFriendlyDate';

const List = () => {
    const [leads, setLeads] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchLeads = async () => {
            setIsLoading(true);
            const response = await api.get('/demo/leads');
            setLeads(response.data);
            setIsLoading(false);
        };
        fetchLeads();
    }, []);

    const handleDelete = (id) => {
        return async () => {
          try {
            setIsLoading(true);
            await api.delete(`/demo/${id}`);
            const response = await api.get("/demo/leads");
            setLeads(response.data); // Adjust based on your API structure
            setIsLoading(false);
          } catch (error) {
            console.error("Failed to delete customer", error);
          }
        };
      };   

    return (
        <Container>
            {isLoading && <p>Loading leads...</p>}
            {leads.length === 0 && !isLoading && <p>No leads found.</p>}
            <h1>Leads</h1>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Url</th>
                        <th>Contact</th>
                        <th>Status</th>
                        <th>Created</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {leads.map((lead) => (
                        <tr key={lead.id}>
                            <td><a href={`/leads/view/${lead.checksum}`}>{lead.id}</a></td>
                            <td>{lead.url}</td>
                            <td>{lead.email}</td>
                            <td>{lead.status}</td>
                            <td><UserFriendlyDate timestamp={lead.created_at} /></td>
                            <td width="120px">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Action
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href={`/leads/view/${lead.checksum}`}>
                      View
                    </Dropdown.Item>
                    <Dropdown.Item as="button" onClick={handleDelete(lead.id)}>
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Container>
    );
}

export default List;