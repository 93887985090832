import axios from 'axios';

// Get the API base URL from environment variables
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Create an Axios instance
const api = axios.create({
  baseURL: API_BASE_URL,
});

// Function to set the Authorization header based on the stored token
export const setAuthToken = token => {
  if (token) {
    // Apply the token to every request if logged in
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    // Delete the auth header if logged out
    delete api.defaults.headers.common['Authorization'];
  }
};

// Export the Axios instance for making API calls
export default api;
