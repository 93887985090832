import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Form, Container, Row, Col, Alert } from "react-bootstrap";
import { AuthProvider, useAuth } from "../AuthContext";
import api from "../../utils/api";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const { login } = useAuth(); // Destructure the login function

  const navigate = useNavigate(); // Getting the navigate function
  const location = useLocation(); // Getting the location object

  const from = location.state?.from?.pathname || "/";

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post(
        "/login",
        `username=${email}&password=${password}`,
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      );

      login(response.data.access_token);
      navigate(from);
    } catch (error) {
      console.error("Login failed:", error);
      setError("Failed to login. Please check your credentials.");
    }
  };

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md={6}>
          <Form onSubmit={handleSubmit} className="mt-5">
            <div className="text-center">
              <img src="logo-lifemind.png" alt="Lifemind Admin" />
            </div>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>

            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
