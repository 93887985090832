import React from "react";
import { scaleQuantize } from "d3-scale";
import { geoCentroid } from "d3-geo";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  Annotation,
} from "react-simple-maps";

// import allStates from "./data/allstates.json";

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";


const allStates = [
    { "id": "AL", "val": "01", "name": "Alabama" },
    { "id": "AK", "val": "02", "name": "Alaska" },
    { "id": "AZ", "val": "04", "name": "Arizona" },
    { "id": "AR", "val": "05", "name": "Arkansas" },
    { "id": "CA", "val": "06", "name": "California" },
    { "id": "CO", "val": "08", "name": "Colorado" },
    { "id": "CT", "val": "09", "name": "Connecticut" },
    { "id": "DE", "val": "10", "name": "Delaware" },
    { "id": "DC", "val": "11", "name": "District of Columbia" },
    { "id": "FL", "val": "12", "name": "Florida" },
    { "id": "GA", "val": "13", "name": "Georgia" },
    { "id": "HI", "val": "15", "name": "Hawaii" },
    { "id": "ID", "val": "16", "name": "Idaho" },
    { "id": "IL", "val": "17", "name": "Illinois" },
    { "id": "IN", "val": "18", "name": "Indiana" },
    { "id": "IA", "val": "19", "name": "Iowa" },
    { "id": "KS", "val": "20", "name": "Kansas" },
    { "id": "KY", "val": "21", "name": "Kentucky" },
    { "id": "LA", "val": "22", "name": "Louisiana" },
    { "id": "ME", "val": "23", "name": "Maine" },
    { "id": "MD", "val": "24", "name": "Maryland" },
    { "id": "MA", "val": "25", "name": "Massachusetts" },
    { "id": "MI", "val": "26", "name": "Michigan" },
    { "id": "MN", "val": "27", "name": "Minnesota" },
    { "id": "MS", "val": "28", "name": "Mississippi" },
    { "id": "MO", "val": "29", "name": "Missouri" },
    { "id": "MT", "val": "30", "name": "Montana" },
    { "id": "NE", "val": "31", "name": "Nebraska" },
    { "id": "NV", "val": "32", "name": "Nevada" },
    { "id": "NH", "val": "33", "name": "New Hampshire" },
    { "id": "NJ", "val": "34", "name": "New Jersey" },
    { "id": "NM", "val": "35", "name": "New Mexico" },
    { "id": "NY", "val": "36", "name": "New York" },
    { "id": "NC", "val": "37", "name": "North Carolina" },
    { "id": "ND", "val": "38", "name": "North Dakota" },
    { "id": "OH", "val": "39", "name": "Ohio" },
    { "id": "OK", "val": "40", "name": "Oklahoma" },
    { "id": "OR", "val": "41", "name": "Oregon" },
    { "id": "PA", "val": "42", "name": "Pennsylvania" },
    { "id": "RI", "val": "44", "name": "Rhode Island" },
    { "id": "SC", "val": "45", "name": "South Carolina" },
    { "id": "SD", "val": "46", "name": "South Dakota" },
    { "id": "TN", "val": "47", "name": "Tennessee" },
    { "id": "TX", "val": "48", "name": "Texas" },
    { "id": "AS", "val": "60", "name": "American Samoa" },
    { "id": "GU", "val": "66", "name": "Guam" },
    { "id": "MP", "val": "69", "name": "Northern Mariana Islands" },
    { "id": "PR", "val": "72", "name": "Puerto Rico" },
    { "id": "UT", "val": "49", "name": "Utah" }, // Note: Corrected from "Utah" to "UT"
    { "id": "VT", "val": "50", "name": "Vermont" },
    { "id": "VA", "val": "51", "name": "Virginia" },
    { "id": "VI", "val": "78", "name": "Virgin Islands" },
    { "id": "WA", "val": "53", "name": "Washington" },
    { "id": "WV", "val": "54", "name": "West Virginia" },
    { "id": "WI", "val": "55", "name": "Wisconsin" },
    { "id": "WY", "val": "56", "name": "Wyoming" },
    { "id": "UM", "val": "74", "name": "United States Minor Outlying Islands" },
  ];

// Dummy scale for demonstration
const colorScale = scaleQuantize()
  .domain([1, 72]) // Assuming 'val' ranges from 01 to 56
  .range([
    "#ffedea",
    "#ffcec5",
    "#ffad9f",
    "#ff8a75",
    "#ff5533",
    "#e2492d",
    "#be3d26",
    "#9a311f",
    "#782618"
  ]);

const Heatmap = () => {
    return (
        <ComposableMap projection="geoAlbersUsa">
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map(geo => {

                console.log(geo.properties)
                // This logic needs adjustment for actual data matching
                const state = allStates.find(s => s.name === geo.properties.name); // pseudo-code, adjust according to actual property
                const fill = state ? colorScale(state.val) : "#EEE";
    
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={fill}
                  />
                );
              })
            }
          </Geographies>
        </ComposableMap>
      );
};

export default Heatmap;
