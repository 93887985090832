import React from 'react';

const UserFriendlyDate = ({ timestamp }) => {
  const date = new Date(timestamp);
  const formattedDate = date.toLocaleDateString("en-US", {
    year: 'numeric', 
    month: 'long', 
    day: 'numeric', 
    hour: '2-digit', 
    minute: '2-digit', 
    //second: '2-digit',
    //timeZoneName: 'short'
  });

  return <div>{formattedDate}</div>;
};

export default UserFriendlyDate;
