import React, { useState, useEffect } from "react";
import { Container, Table, Form, Button } from "react-bootstrap";
import api from "../../../utils/agentApi";

function CreateRun() {
  const [runName, setRunName] = useState("");
  const [runDescription, setRunDescription] = useState("");
  const [sourceSequences, setSourceSequences] = useState({});
  const [targetAgent, setTargetAgent] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [listSequences, setListSequences] = useState([]);

  const sourceSequencesArray = [
    { id: 1, name: "agent_1" },
    { id: 2, name: "agent_2" },
    { id: 3, name: "agent_3" },
    { id: 4, name: "agent_4" },
    { id: 5, name: "agent_5" },
    { id: 6, name: "agent_6" },
    { id: 7, name: "agent_7" },
    { id: 8, name: "agent_8" },
  ];

  useEffect(() => {
    const fetchLeads = async () => {
      setIsLoading(true);
      const response = await api.get("/get_sequences");
      setListSequences(response.data.data);
      setIsLoading(false);
    };
    fetchLeads();
  }, []);

  const handleSourceAgentChange = (e, id) => {
    setSourceSequences({ ...sourceSequences, [id]: e.target.value });

    console.log(sourceSequences);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const runData = {
      name: runName,
      description: runDescription,
    };

    try {
      alert("Run created successfully");
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  return (
    <Container>
      <h1>Create a new Run</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Run Name</Form.Label>
          <Form.Control
            type="text"
            value={runName}
            onChange={(e) => setRunName(e.target.value)}
            placeholder="Name"
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Run Description</Form.Label>
          <Form.Control
            as="textarea"
            value={runDescription}
            onChange={(e) => setRunDescription(e.target.value)}
            placeholder="Description"
          />
        </Form.Group>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Sequences</th>
            </tr>
          </thead>
          <tbody>
            {sourceSequencesArray.map((agent) => (
              <tr key={agent.id}>
                <td>{agent.id}</td>
                <td>
                  <Form.Control
                    as="select"
                    value={sourceSequences[agent.id] || ""}
                    onChange={(e) => handleSourceAgentChange(e, agent.id)}
                  >
                    <option value="">Select</option>
                    {listSequences.map((listAgent) => (
                      <option key={listAgent.id} value={listAgent.id}>
                        {listAgent.name}
                      </option>
                    ))}
                  </Form.Control>
                    
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <Button variant="primary" type="submit" disabled={isLoading}>
          {isLoading ? "Loading..." : "Submit"}
        </Button>
      </Form>
    </Container>
  );
}

export default CreateRun;
