import React, { useState, useEffect } from "react";
import { Table, Dropdown, Spinner, Modal, Button } from "react-bootstrap";
import UserFriendlyDate from "../../Utils/UserFriendlyDate";
import DataSummary from "./DataSummary";
import qs from "qs";
import api from "../../../utils/api";

const Files = ({ report_id, report_state }) => {
  const [reportFiles, setReportFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);

  useEffect(() => {
    if (report_state) {
      let status_filter = null;

      if (report_state == "merged") {
        status_filter = "merged";
      }

      fetchFiles(status_filter);
    }
  }, [report_state]);

  const fetchFiles = async (status_filter) => {
    try {
      const response = await api.get(`/report/files/${report_id}`, {
        params: { status: status_filter },
      });
      setReportFiles(response.data);
    } catch (error) {
      console.error("Failed to fetch files", error);
    }
  };

  const downloadFile = async (filename) => {
    try {
      // Constructing the form data as x-www-form-urlencoded
      const data = qs.stringify({
        file_key: filename,
      });

      const config = {
        method: "post",
        url: "/report/download-file/",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          accept: "application/json",
        },
        data: data,
        responseType: "blob", // Indicates that the response should be treated as a Blob
      };

      const response = await api(config);

      // Handling the Blob response to trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "downloadedFile.csv"); // Set a default filename or derive from response headers
      document.body.appendChild(link);
      link.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
      alert("Failed to download file.");
    }
  };

  const handleClean = async (file_id) => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("file_id", file_id);

      const response = await api.post("/report/clean/", formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          //setUploadProgress(percentCompleted); // Update upload progress
        },
      });

      fetchFiles();
      setIsLoading(false);
    } catch (error) {
      console.error("Error downloading file:", error);
      alert("Failed to clean file.");
    }
  };

  // Function to handle the delete confirmation
  const handleDeleteConfirm = async () => {
    if (fileToDelete) {
      try {
        const response = await api.delete(`/file/${fileToDelete}`);
        console.log("File deleted", response);
        fetchFiles();
        handleClose(); // Close the modal after deletion
      } catch (error) {
        console.error("Failed to delete file", error);
        handleClose(); // Close the modal if there's an error
      }
    }
  };

  // Show the confirmation modal with the file_id to delete
  const handleShow = (file_id) => {
    setShow(true);
    setFileToDelete(file_id);
  };

  // Close the confirmation modal
  const handleClose = () => {
    setShow(false);
    setFileToDelete(null);
  };

  const handleMerge = async (file_id) => {
    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append("report_id", report_id);
      formData.append("file_id", file_id);

      const response = await api.post("/report/merge/", formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          //setUploadProgress(percentCompleted); // Update upload progress
        },
      });

      fetchFiles();
      setIsLoading(false);
    } catch (error) {
      console.error("Error Merging file:", error);
      alert("Failed to merge file.");
    }
  };

  const previewFile = async (filename) => {
    console.log("Previewing file", filename);
    window.open(`/report/preview-csv?file_key=${filename}`);
  };

  return (
    <>
      <div>
        {isLoading && (
          <div className="text-center">
            <Spinner animation="grow" />
          </div>
        )}

        <h4 className="mt-4">CSV files</h4>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Original File</th>
              {report_state !== "draft" && <th>Cleaned File</th>}
              {report_state === "merged" && <th>Merged File</th>}
              <th>Status</th>
              <th>Created</th>
              <th>Summary</th>
              {report_state !== "merged" && <th>Action</th>}
            </tr>
          </thead>
          <tbody>
            {reportFiles.map((file) => (
              <tr key={file.id}>
                <td>{file.id}</td>
                <td>
                  <a
                    href="#"
                    onClick={() =>
                      downloadFile(file.original_filename_location)
                    }
                  >
                    {file.original_filename}
                  </a>
                  <br />[
                  <a
                    href="#"
                    className="small"
                    onClick={() => previewFile(file.original_filename_location)}
                  >
                    Preview
                  </a>
                  ]
                </td>
                {file.cleaned_filename !== null && file.cleaned_filename !== "" && (
                <td>
                  <a
                    href="#"
                    onClick={() => downloadFile(file.cleaned_filename_location)}
                  >
                    {file.cleaned_filename}
                  </a>
                  <br />[
                  <a
                    href="#"
                    className="small"
                    onClick={() => previewFile(file.cleaned_filename_location)}
                  >
                    Preview
                  </a>
                  ]
                </td>                
)}
                {report_state === "merged" && (
                  <td>
                    {" "}
                    <a
                      href="#"
                      onClick={() =>
                        downloadFile(file.merged_filename_location)
                      }
                    >
                      {file.merged_filename}
                    </a>
                    <br />[
                    <a
                      href="#"
                      className="small"
                      onClick={() => previewFile(file.merged_filename_location)}
                    >
                      Preview
                    </a>
                    ]
                  </td>
                )}
                <td>{file.state}</td>
                <td>
                  <UserFriendlyDate timestamp={file.created_at} />
                </td>
                <td>
                  <DataSummary data={file.summary} />
                </td>

                {file.state !== "merged" && (
                  <td width="120px">
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        Action
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {file.state === "uploaded" && (
                          <Dropdown.Item
                            href="#"
                            onClick={() => handleClean(file.id)}
                          >
                            Clean
                          </Dropdown.Item>
                        )}
                        {file.state === "cleaned" && (
                          <Dropdown.Item
                            href="#"
                            onClick={() => handleMerge(file.id)}
                          >
                            Merge
                          </Dropdown.Item>
                        )}
                        <Dropdown.Item
                          href="#"
                          onClick={() => handleShow(file.id)}
                        >
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {/* Confirmation Modal */}
      {show && (
        <Modal show={show} onHide={handleClose} animation={true}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button variant="danger" onClick={handleDeleteConfirm}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default Files;
