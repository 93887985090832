import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import api from "../../utils/api";

const Edit = () => {
    
    let { id } = useParams();
    
    const [prompt, setPrompt] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchPrompt = async () => {
            setIsLoading(true);
            const response = await api.get(`/prompts/${id}`);
            setPrompt(response.data);
            setIsLoading(false);
        };
        fetchPrompt();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPrompt((prevPrompt) => ({
            ...prevPrompt,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        //alert("Prompt update not implemented yet");
        try {
            await api.put(`/prompts/${id}`, prompt);
            alert("Prompt updated successfully");
        } catch (error) {
            console.error("Failed to update prompt", error);
        }
    };

    return (
        <Container>
            {isLoading && <p>Loading prompt...</p>}
            {!isLoading && (
                <>
                    <h1>Edit Prompt</h1>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="2">
                                Name
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={prompt.name}
                                    onChange= {handleChange}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="2">
                                Description
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    as="textarea"
                                    name="description"
                                    value={prompt.description}
                                    onChange= {handleChange}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="2">
                                Prompt
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    as="textarea"
                                    name="prompt"
                                    value={prompt.prompt}
                                    onChange= {handleChange}
                                />
                            </Col>
                        </Form.Group>
                        <Button type="submit">Update</Button>
                    </Form>
                </>
            )}
        </Container>
    );
};

export default Edit;