import React, { useState, useEffect } from "react";
import axios from "axios";
import Papa from "papaparse";
import {
  Container,
  Card,
  Table,
  Pagination,
  Form,
  DropdownButton,
  Dropdown,
  Row,
  Col,
} from "react-bootstrap";
import { useLocation } from "react-router-dom";
import api from "../../utils/api";
import qs from "qs";

const CsvDisplayTable = () => {

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [searchTerm, setSearchTerm] = useState("");

  const location = useLocation();

  // Function to parse query string
  const useQuery = () => {
    return new URLSearchParams(location.search);
  }

  const query = useQuery();
  const file_key = query.get('file_key'); // Get file_key from URL

  useEffect(() => {
    const fetchData = async () => {

      console.log("Fetching data for file_key: ", file_key);

      try {
        const data = qs.stringify({
          file_key: file_key,
        });

        const config = {
          method: "post",
          url: "/report/download-file/",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            accept: "application/json",
          },
          data: data,
          responseType: "blob", // Indicates that the response should be treated as a Blob
        };

        const response = await api(config);

        Papa.parse(response.data, {
          complete: (result) => {
            setData(result.data);
            setFilteredData(result.data);
          },
          header: true,
        });
      } catch (error) {
        console.error("Error fetching the CSV file: ", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    const searchFilteredData = data.filter((row) =>
      Object.values(row).some((value) =>
        value.toLowerCase().includes(lowercasedSearchTerm)
      )
    );
    setFilteredData(searchFilteredData);
    setCurrentPage(1); // Reset to page 1 after search
  }, [searchTerm, data]);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = data.slice(indexOfFirstRow, indexOfLastRow);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Handle Rows Per Page Change
  const handleRowsPerPageChange = (rows) => {
    setRowsPerPage(rows);
    setCurrentPage(1); // Reset to page 1 when rows per page changes
  };

  // Pagination Items Logic
  const paginationItems = () => {
    const pageNumbers = [];
    const totalPages = Math.ceil(data.length / rowsPerPage);
    const pageLimit = 10;
    let maxLeft = currentPage - Math.floor(pageLimit / 2);
    let maxRight = currentPage + Math.floor(pageLimit / 2);

    if (maxLeft < 1) {
      maxLeft = 1;
      maxRight = pageLimit;
    }

    if (maxRight > totalPages) {
      maxLeft = totalPages - (pageLimit - 1);
      maxRight = totalPages;

      if (maxLeft < 1) maxLeft = 1;
    }

    for (let number = maxLeft; number <= maxRight; number++) {
      pageNumbers.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => paginate(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    if (maxLeft > 1) {
      pageNumbers.unshift(
        <Pagination.Item key="prev" onClick={() => paginate(maxLeft - 1)}>
          ...
        </Pagination.Item>
      );
    }

    if (maxRight < totalPages) {
      pageNumbers.push(
        <Pagination.Item key="next" onClick={() => paginate(maxRight + 1)}>
          ...
        </Pagination.Item>
      );
    }

    return pageNumbers;
  };

  return (
    <>
      <Container className="mt-4">
        <Card>
          <Card.Header>Preview CSV File: {file_key}</Card.Header>
          <Card.Body>
            <Card.Title>CSV Data</Card.Title>
            <Card.Text>
              Displaying {indexOfFirstRow + 1} to {indexOfLastRow} of{" "}
              {data.length} rows
            </Card.Text>
            <Row className="p-2">
              <Col>
                <DropdownButton
                  id="dropdown-basic-button"
                  title={`Rows per page: ${rowsPerPage}`}
                >
                  {[20, 50, 100].map((size) => (
                    <Dropdown.Item
                      key={size}
                      onClick={() => handleRowsPerPageChange(size)}
                    >
                      {size}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Col>
              <Col>
                <Form.Group controlId="search">
                  <Form.Control
                    type="text"
                    placeholder="Search..."
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Table striped bordered hover>
              <thead>
                {data.length > 0 && (
                  <tr>
                    {Object.keys(data[0]).map((key) => (
                      <th key={key}>{key}</th>
                    ))}
                  </tr>
                )}
              </thead>
              <tbody>
                {currentRows.map((row, index) => (
                  <tr key={index}>
                    {Object.values(row).map((val, i) => (
                      <td key={i}>{val}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
            <Card.Footer>
              <Pagination>{paginationItems()}</Pagination>
            </Card.Footer>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};

export default CsvDisplayTable;
