import React, { createContext, useContext, useState, useEffect } from "react";
import { setAuthToken } from "../utils/api";
import { jwtDecode } from "jwt-decode";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Add a loading state

  useEffect(() => {
    
    // Get the token from local storage
    const token = localStorage.getItem("token");

    // Check if token is expired
    if (token !== null) {
        const decode_token = jwtDecode(token);

        // Remove token if expiredz
        if(decode_token.exp < Date.now() / 1000) {
          localStorage.removeItem("token");
          token = null;
        }
    }

    // Set the token and loading state
    setIsAuthenticated(!!token);
    setAuthToken(token);
    setIsLoading(false);
  }, []);

  const login = (token) => {
    localStorage.setItem("token", token);
    setIsAuthenticated(true);
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("currentUser");
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, isLoading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
