import React, { useEffect, useState } from "react";
import { Container, Navbar, Nav } from "react-bootstrap";

import api from "../utils/api";

const TopNav = () => {
  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    console.log('Running effect');
    getCurrentUser();
  }, []); // Include dependencies

  const getCurrentUser = async () => {
    console.log("getCurrentUser");
    let currentUser = null;
    if (!localStorage.getItem("currentUser")) {
      try {
        const response = await api.get("/users/me");
        localStorage.setItem("currentUser", JSON.stringify(response.data));
        setCurrentUser(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Failed to fetch Users", error);
      }
    } else {
      currentUser = localStorage.getItem("currentUser");
      setCurrentUser(JSON.parse(currentUser));
    }
    console.log(currentUser);
  };

  return (
    <Navbar bg="light" data-bs-theme="light">
      <Container>
        <Navbar.Brand href="#home">
          <img src="/logo-lifemind.png" width="120px" alt="Lifemind Admin" />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            Signed in as: <a href="/settings/account">{currentUser.email}</a>
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default TopNav;
